import React from 'react'
import About from '../components/About'

const AboutContact = () => {
  return (
    <div>
      <About/>
    </div>
  )
}

export default AboutContact
